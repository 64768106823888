import React, { useState } from 'react'
import { css } from '@emotion/react'


// https://codepen.io/hunzaboy/pen/qBWRBXw


const colorSwitch = (color) => ({
  "one": { dark: `#1E9E8D`, light: `#2CE8CF` },
  "two": { dark: `#24ABBF`, light: `#2ED7F2`, },
  "three": { dark: `#3498DB`, light: `#1BCEFF`, },
  "four": { dark: `#2E70F2`, light: `#2E9EF2`, },
  "five": { dark: `#2C3AE8`, light: `#229FF5`, }
})[color]



const TimelineEventDate = ({ date }) => (
  <div className="timeline__event__date" css={{
    fontSize: `1.5rem`,
    fontWeight: `600`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    whiteSpace: `nowrap`,
    padding: `0 20px`,
    borderRadius: `6px 0 0 6px`,
  }}>
    {date}
  </div>
)

const TimelineEventTitle = ({ title, days }) => (
  <div className="timeline__event__title" css={{
    fontSize: `1.2rem`,
    lineHeight: `1.4`,
    textTransform: `uppercase`,
    fontWeight: `600`,
    letterSpacing: `1.5px`,
  }}>
    {title}
    <small>&nbsp;({days})</small>
  </div>
)

const TimelineEventDescription = ({ desciption }) => (
  <div css={{flexBasis: `60%`}}>
    <p css={{margin: 0}}>{desciption}</p>  
  </div>
)

const TimelineEvent = ({ days, date, title, desciption, color }) => {

  let eventColor = colorSwitch(color || 'one')

  return (
    <div className="timeline__event animated fadeInUp " 
      css={{
        marginBottom: `30px`,
        position: `relative`,
        display: `flex`,
        margin: `30px 0`,
        borderRadius: `6px`,
        alignSelf: `center`,
        width: `100%`,

        '&:before': {
          content: `""`,
          width: `2px`,
          height: `100%`,
          marginTop:`60px`,
          position: `absolute`,
          top: `0%`,
          left: `50%`,
          right: `auto`,
          zIndex: `-1`,
          background: eventColor.dark
        },
        '&:last-of-type:before': {display: `none`},

        // Handle Color Change
        '.timeline__event__icon': { background: eventColor.light, color: eventColor.dark, fill: eventColor.dark },
        '.timeline__event__date': { background: eventColor.dark, color: eventColor.light },
        '.timeline__event__title': { color: eventColor.dark },
      }}
    >
      <TimelineEventDate date={date} />
      <div 
        className='timeline__event__content'
        css={{
          padding: `20px`,
          boxShadow: `0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025)`,
          background: `#fff`,
          width: `calc(40vw - 84px)`,
          borderRadius: `0 6px 6px 0`,
      }}>
        <TimelineEventTitle title={title} days={days}/>
        <TimelineEventDescription desciption={desciption}/>
      </div>
    </div>
  )
}



const Timeline = ({ }) => {
  return (
    <div css={css`
      .timeline {
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        position: relative;
        flex
      }      
      @media (max-width: 934px) {
        .timeline__event {
          flex-direction: column;
          align-self: center;
        }
        .timeline__event__content {
          width: 100%;
        }
        .timeline__event__icon {
          border-radius: 6px 6px 0 0;
          width: 100%;
          margin: 0;
          box-shadow: none;
        }
        .timeline__event__icon:before, .timeline__event__icon:after {
          display: none;
        }
        .timeline__event__date {
          border-radius: 0;
          padding: 20px;
        }
        .timeline__event:nth-child(2n+1) {
          flex-direction: column;
          align-self: center;
        }
        .timeline__event:nth-child(2n+1) .timeline__event__date {
          border-radius: 0;
          padding: 20px;
        }
        .timeline__event:nth-child(2n+1) .timeline__event__icon {
          border-radius: 6px 6px 0 0;
          margin: 0;
        }
      }
      `}>
      <div className="timeline"> 

  

<TimelineEvent
  date="Step 1"
  title="Application and Disclosures"
  days="1-3 days"
  desciption="Submit a full application and necessary documents. Within 3 days, the loan officer will contact the applicant and provide a loan estimate featuring estimated closing costs."
/>
<TimelineEvent
  date="Step 2"
  title="Loan Submission and Processing"
  days="5-15 days"
  desciption="The loan officer and/or assistant will verify documentation submitted and that the file is complete for underwriting. An appraisal to establish a value and flood certification will be ordered on the real estate of interest."
  color="two"
/>
<TimelineEvent
  date="Step 3"
  title="Loan Underwriting"
  days="15-20 days"
  desciption="The loan officer will review applicants’ financial documents and verify the applicant and collateral meets guidelines for final approval."
  color="three"
/>
<TimelineEvent
  date="Step 4"
  title="Pre-Closing"
  days="3-5 days"
  desciption="The last details are finalized by the loan officer and the closing attorney. Closing date and time is established."
  color="four"
/>
<TimelineEvent
  date="Step 5"
  title="Closing Day"
  days="5-7 days"
  desciption="The borrower will receive a closing disclosure 3 days prior to closing, to review and sign. On the date scheduled, all parties will attend the closing and sign documents to finalize the loan."
  color="five"
/>
</div>
    </div>
  )
}

export default Timeline