import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useForm, useFormState } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import moment from 'moment'
import Botpoison from "@botpoison/browser"



function encode(data) {
  return Object.keys(data)
      .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object({
  fullName: yup.string()
    .required('Your name is required.')
    .min(4, 'Please enter your full name.')
    .max(80, 'Please enter a valid name.'),
  DOB: yup.date().typeError("Please enter a valid date.")
    .required('A date of birth is required')
    .max(moment().subtract(18, 'years'), 'You must be 18 or older to apply for a mortage.'),
  currentAddress: yup.string()
    .required('Your current address is required.')
    .min(20, 'Please enter a valid address.')
    .max(100, 'Please enter a valid address.'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid. (Format: 912-739-3322)')
    .min(10, 'Please include your area code.')
    .max(12, 'Please enter a valid phone number. (Format: 912-739-3322)'),
  emailAddress: yup.string().email("Please enter a valid email address."),
  loanAmount: yup.number().typeError("Please enter a valid loan amount.")
    .required("Please enter a valid loan amount.")
    .positive("Please enter a valid loan amount.")
    .integer("Please enter a valid loan amount.")
    .moreThan(10000, "Mortgages must be over $10,000.00")
    .lessThan(5000000, "Mortgages must be under $5,000,000.00"), 
  loanPurpose: yup.string().required().min(2)              
}).required();

const MortgageForm = () => {


  const { register, handleSubmit, watch, reset, formState: { errors, isSubmitted, isDirty, isValid } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  })

  const watchDOB = watch("DOB", false)
  const watchPurpose = watch("loanPurpose", false)
 


  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
            'form-name': 'mortgage-inquiry-form',
            ...data,
        }),
    })
        .then(response => {
            //reset()
            //navigate(form.getAttribute('action'))
            console.log(response)
        })
        .catch(error => {
            console.log(error)
        })
}
  
  return (
    <div>
    <div css={{overflow: `hidden`, maxWidth: `400px`, display: isSubmitted ? 'none' : `block` }}>
      <form 
        onSubmit={handleSubmit(onSubmit)}
        name="mortgage-inquiry-form"
        method="POST"
        action="https://submit-form.com/eU5d7xtl"
        data-botpoison-public-key="pk_03ee8e89-33e2-4fd6-9ba1-e105f8f133a2"
        css={{
          maxWidth: `400px`,
          '.fieldBlock': {
            padding: `0.5rem 0 0 0`,

            '.labelText': {
              display: `inline-block`,
              paddingLeft: `0.6rem`,
              fontSize: `1.rem`,
              color: `#2f3640`,
            },

            '.required': {
              display: `inline-block`,
              paddingLeft: `0.25rem`,
              color: `#c0392b`
            },

            'input[type="date"]::-webkit-datetime-edit-text, input[type="date"]::-webkit-datetime-edit-month-field, input[type="date"]::-webkit-datetime-edit-day-field,input[type="date"]::-webkit-datetime-edit-year-field': {
              color: watchDOB ? `#353b48` : `#888`,
              fontSize: `0.889rem`,
              height: `2rem`
            },

            'input, select': {
              margin: `0`,
              display: `block`,
              width: `100%`,
              //width: `400px`,
              maxWidth: `400px`,
              outline: `none`,
              borderRadius: `0.5rem`,
              border: `1px solid #bdc3c7`,
              fontSize: `0.889rem`,
              height: `2rem`,
              padding: `0 1rem`,
              color: `#353b48`,
              fontFamily: `source-sans-pro, "Segoe UI", Tahoma, Geneva, sans-serif !important`,
              backgroundColor: `rgba(255,255,255, 0.6)`,
            },
            '.error': {
              border: `1px solid #e3b9b9`,
              backgroundColor: `#fef0f0`,
              color: `#c0392b`
            },
            '.errorMessage': {
              height: `1.5rem`,
              paddingLeft: `0.6rem`,
              color: `#c0392b`
            }
          }
        }}
      >

<input
    type="hidden"
    name="_redirect"
    value="https://tcbga.bank/personal/mortgage"
  />

        <div className="fieldBlock">
          <label>
            <span className="labelText">Full Name</span>
            <span className="required">*</span>
            <input {...register("fullName")} placeholder="John Smith" />
            <div className="errorMessage">
              <small>{errors.fullName?.message}</small>
            </div>
          </label>
        </div>

        <div className="fieldBlock">
          <label>
            <span className="labelText">Date of Birth</span>
            <span className="required">*</span>
            <input type="date" {...register("DOB")} placeholder="07/24/1990" />
            <div className="errorMessage">
              <small>{errors.DOB?.message}</small>
            </div>
          </label>
        </div>

        <div className="fieldBlock">
          <label>
            <span className="labelText">Current Address</span>
            <span className="required">*</span>
            <input {...register("currentAddress")} placeholder="121 W Main St Claxton GA 30417" />
            <div className="errorMessage">
              <small>{errors.currentAddress?.message}</small>
            </div>
          </label>
        </div>

        <div className="fieldBlock">
          <label>
            <span className="labelText">Phone Number</span>
            <span className="required">*</span>
            <input type="tel" {...register("phoneNumber")} placeholder="912-739-3322" />
            <div className="errorMessage">
              <small>{errors.phoneNumber?.message}</small>
            </div>
          </label>
        </div>

        <div className="fieldBlock">
          <label>
            <span className="labelText">Email Address</span>
            <span className="required">*</span>
            <input type="email" {...register("emailAddress")} placeholder="info@tcbga.bank" />
            <div className="errorMessage">
              <small>{errors.emailAddress?.message}</small>
            </div>
          </label>
        </div>

        <div className="fieldBlock">
          <label>
            <span className="labelText">Desired Loan Amount</span>
            <span className="required">*</span>
            <input type="number" {...register("loanAmount")} placeholder="$100,000.00" />
            <div className="errorMessage">
              <small>{errors.loanAmount?.message}</small>
            </div>
          </label>
        </div>

        <div className="fieldBlock">
          <label>
            <span className="labelText">Loan Purpose</span>
            <span className="required">*</span>
            <select {...register("loanPurpose")} css={{color: watchPurpose  ?  `#353b48` : `#888 !important`}}>
              <option disabled value="" selected css={{color: `gray !important`}}> -- select an option -- </option>
              <option value="Purchase">Purchase a new home</option>
              <option value="Refinance">Refinance your current home</option>
              <option value="Construction">Build a new home</option>
              <option value="HELOC">Home Equity Line of Credit (HELOC)</option>
            </select>
            <div className="errorMessage">
              <small>{errors.loanPurpose?.message}</small>
            </div>
          </label>
        </div>

        <button
          type="submit"
          disabled={!isDirty || !isValid} 
          css={{
            margin: `1rem 0`,
            padding: `0.75rem 2rem`,
            backgroundColor: `#2ecc71`,
            borderRadius: `0.5rem`,
            border: `1px solid #27ae60`,
            color: `#fff`,
            outline: `none`,
            width: `100%`,
            maxWidth: `400px`,
            transition: `all 1s ease`,
            '&:disabled': {
              backgroundColor: `#d2ded7`,
              borderColor: `#91ad9d`
            }
          }}
        >
          Submit Form
        </button>
      </form>
    </div>
    <div css={{ minHeight: `689px`, display: isSubmitted ? 'block' : `none`}}>
      Form Submitted
    </div>
    </div>
  )
}

export default MortgageForm

